import React from 'react';
import { parse } from 'query-string';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import _ from 'underscore';
import Cookies from 'js-cookie';

import { safeCredentials, handleErrors } from '../../utils/fetchHelpers';

import './Layout.scss';

const muiTheme = getMuiTheme({
  palette: {
    primary1Color: '#1647fb',
    primary2Color: '#6281FF',
    textColor: '#333',
  },
  userAgent: 'all',
});

class Layout extends React.Component {
  componentDidMount () {
    fetch('/api/v2/authenticated_user')
      .then(handleErrors)
      .then(user => {
        if (user.currentUser && window.ffk) {
          window.ffk('people', 'set', {email: user.currentUser.email});
        }
      })
      .catch(() => {
        console.error('not logged in')
      })

    const query = parse(window.location.search);
    
    if (Object.keys(query).length > 0) {
      const whitelistKeys = Object.keys(query).filter(key => key.includes('utm_') || key.includes('fbclid') || key.includes('ffk_') || key.includes('ref'));
      if (whitelistKeys.length > 0) {
        const newReferral = _.pick(query, whitelistKeys);
        newReferral.time = new Date().toUTCString();

        if (window && window.ffk) {
          newReferral.landing_page = window.location.href;

          if (document && document.referrer) {
            newReferral.doucment_referrer = document.referrer;
          }

          if (newReferral.ffk_person_id) {
            window.ffk('people', 'set', {email: newReferral.ffk_person_id});
          }
          window.ffk('track', 'Referral', newReferral);
        }

        if (Cookies.get("referral_history")) {
          const history = Cookies.getJSON("referral_history");
          history.push(newReferral);
          Cookies.set("referral_history", history, { expires: 7300 });
        } else {
          Cookies.set("referral_history", [newReferral], { expires: 7300 });
        }
      }
      if (query.discount_code && query.discount_code === 'videoask10') {
        Cookies.set("discount_code", 'videoask10', { expires: 30 });
      }
      if (query.discount_code && query.discount_code === 'pro10') {
        Cookies.set("discount_code", 'pro10', { expires: 30 });
      }
      if (query.discount_code && query.discount_code === 'cyber15') {
        Cookies.set("discount_code", 'cyber15', { expires: 30 });
      }
    } else if (document.referrer && !document.referrer.includes('https://www.altcademy.com/')) {
      const newReferral = {
        time: new Date().toUTCString(),
      };

      if (window && window.ffk) {
        newReferral.landing_page = window.location.href;

        if (document && document.referrer) {
          newReferral.doucment_referrer = document.referrer;
        }

        window.ffk('track', 'Referral', newReferral);
      }

      if (Cookies.get("referral_history")) {
        const history = Cookies.getJSON("referral_history");
        history.push(newReferral);
        Cookies.set("referral_history", history, { expires: 7300 });
      } else {
        Cookies.set("referral_history", [newReferral], { expires: 7300 });
      }
    }
  }

  render() {
    const { server_data, ComponentToRender } = this.props;

    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <React.Fragment>
          <ComponentToRender server_data={server_data} />
        </React.Fragment>
      </MuiThemeProvider>
    );
  }
}

const App = (props) => {
  let server_data = {};
  const node = document.getElementById('server_data');
  if (node) {
    server_data = JSON.parse(node.getAttribute('data'));
  }

  return <Layout {...props} server_data={server_data} />;
}

export default App
